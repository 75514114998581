import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Articles from "../components/articles"
import Banner from "../components/banner";

const FeaturePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="特集" keywords={[`特集`, `働き方`, `リモートデスクトップ`, `時短`]} />
      <Articles title="特集一覧" data={data.articles}/>
      <Banner/>
    </Layout>
  );
}

export default FeaturePage;

export const query = graphql`
query {
    articles: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { fields: { attributes: { in: ["feature"] }}}) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YY年M月D日")
            image {
              childImageSharp {
                fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          fields {
            title
            slug
            attributes
            summary
            tags
          }
          excerpt
        }
      }
    }  
  } 
`
